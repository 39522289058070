@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.ant-tabs {
  width: auto;
  min-width: unset !important;
}