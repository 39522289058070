.container {
  display: block;
  overflow: hidden;
  margin: 0 auto;
}

.container:hover img {
  transform: scale(1.3);
  transition: all 0.4s ease;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}
